<template>
	<LinkTile
		v-if="to"
		:aria-labelledby="`kultunaut-card-${_uid}`"
		class="c-kultunaut-card__container relative"
		v-bind="{ to, target }"
	>
		<div
			class="c-kultunaut-card rounded-lg overflow-hidden h-full"
			:class="light ? 'bg-white' : 'bg-primary-medium'"
		>
			<div
				v-if="image"
				:class="[
					'c-kultunaut-card__image',
					'relative w-full h-0 overflow-hidden',
					'bg-primary-light',
				]"
				:style="{
					'padding-top': `${
						(image.aspectRatio
							? 1 / image.aspectRatio
							: image.height / image.width) * 100
					}%`,
				}"
			>
				<UmbracoImageExt
					:widths="[320, 480, 640, 480 * 2, 640 * 2]"
					:alt="image.altText"
					:source-url="image.cropUrl"
					:source-width="image.width"
					:source-height="image.height"
					:aspect-ratio="
						image.aspectRatio || image.width / image.height
					"
					:class="[
						'absolute top-1/2 left-1/2',
						'w-full bg-primary-medium',
						'duration-500 ease-smooth-out',
					]"
				/>
			</div>

			<div
				:class="[
					'c-kultunaut-card__content',
					'flex flex-col',
					'h-full',

					{
						'p-md >=768:p-sm >=768:pb-md': size === 'small',
						'p-md py-sm': size === 'medium',
					},
				]"
			>
				<span>
					<div v-if="$scopedSlots.before" class="uppercase mb-xs">
						<slot name="before"></slot>
					</div>

					<BaseH3
						v-if="name"
						:id="`kultunaut-card-${_uid}`"
						v-text="name"
					/>
				</span>

				<div v-if="$scopedSlots.after" class="mt-xs text-h8">
					<slot name="after"></slot>
				</div>
			</div>
		</div>

		<BaseButton
			tag="div"
			:class="[
				'c-kultunaut-card__arrow-button',
				'w-fit z-10 mx-auto -mt-14',
			]"
		>
			<template #icon>
				<SvgArrowIcon class="w-14 h-12 text-white" />
			</template>
		</BaseButton>
	</LinkTile>
</template>

<script>
import LinkTile from '~/citi-baseline/components/LinkTile';
import UmbracoImageExt from '~/components/shared/UmbracoImageExt';
import SvgArrowIcon from '~/assets/svgs/arrow-icon.svg?inline';

export default {
	name: 'KultunautCard',
	components: {
		LinkTile,
		UmbracoImageExt,
		SvgArrowIcon,
	},

	props: {
		image: {
			type: Object,
			required: false,
		},
		to: {
			type: String,
			required: true,
		},
		target: {
			type: String,
			required: false,
		},
		name: {
			type: String,
			required: false,
		},
		overline: {
			type: String,
			required: false,
		},
		light: {
			type: Boolean,
			required: false,
		},
		size: {
			type: String,
			default: 'medium',
			validator: (value) => ['small', 'medium', 'custom'].includes(value),
		},
	},
};
</script>

<style>
.c-kultunaut-card__container .c-umbraco-image {
	@apply transform -translate-x-1/2 -translate-y-1/2;
}

.c-kultunaut-card__container[data-hover='hover'] .c-umbraco-image {
	@apply scale-110;
}
</style>
