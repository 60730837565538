<!--
	Last modified: 2024/06/18 12:32:01
-->
<template>
	<div v-once class="c-post-scribe-script"></div>
</template>

<script>
export default {
	name: 'PostScribeScript',

	props: {
		html: {
			type: String,
			required: true,
		},
		lazyload: {
			type: Boolean,
			default: false,
		},
		reloadOnLeave: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			observer: null,
		};
	},

	beforeMount() {
		this.reloadOnLeave &&
			this.$router.beforeEach(async (to) => {
				window.location.assign(to.fullPath);
			});
	},

	mounted() {
		if (this.lazyload) {
			this.observer = new IntersectionObserver(
				(entries) => {
					if (entries[0].isIntersecting) {
						this.inject();
						this.observer?.disconnect?.();
					}
				},
				{
					rootMargin: `${Math.min(200, window.innerHeight / 10)}px`,
				}
			);
			this.$el && this.observer.observe(this.$el);
		} else {
			this.inject();
		}
	},

	beforeDestroy() {
		this.observer?.disconnect?.();
	},

	methods: {
		inject() {
			const postscribe = require('postscribe');
			postscribe(this.$el, this.html, {
				// We emit some of the availabe callbacks (https://www.npmjs.com/package/postscribe#usage)
				done: () => {
					this.$emit('done');
				},
				error: (error) => {
					this.$emit('error', error);
				},
			});
		},
	},
};
</script>
